.App {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222222;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paddingTop {
  padding-top: 10pt;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  background-color: darkgreen;
}

.red {
  background-color: crimson;
}
